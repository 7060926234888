import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: "transparent",
      },
      head: {
        background: "#F4F7FF",
      },
    },
    MuiTableRow: {
      root: {},
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      root: {
        background: "#FFF",
        borderRadius: "5px",
        height: "50px",
        "& .Mui-focused": {
          color: "#262626",
          border: "1px solid #E7E7E7",
        },
      },

      input: {
        padding:"15.5px 14px",
        
        "&::placeholder": {
          // color: "#636880",
          // color:"#8a8c95",
          fontFamily: "Roboto Condensed",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        },
      },
      notchedOutline: {
        borderColor: "#E7E7E7",
      },
      colorSecondary: {},
    },

    MuiSelect: {
      select: {
        backgroundColor: "#FFF",
        borderRadius: "5px",
        border: "1px solid #E7E7E7",
        // color: "#b8bac1",
        color:"#8a8c95",
        // fontSize:"18px",
        fontSize:"inherit",
        "@media (max-width:600px)":{
          fontSize:"12px",
        },
        "&:focus": {
          backgroundColor: "#F2F2F2",
        },
      },
    },
    
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation1: {
        background: "#fff",
        borderRadius: "10px",
        padding: "26px 20px",
        boxShadow: "none",
      },
      elevation2: {
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 68.15%)",
        border: "none",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "none",
      },
    },

    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },

    MuiMenuItem: { root: { paddingLeft: "20px" } },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#4D164F" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "scroll",
      },
      paperWidthSm: {
        maxWidth: "900px",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#262626",
        // color:"#8a8c95",
        
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiAutocomplete: {
      option: {
        fontFamily: "Poppins !important",
        fontSize: "12px !important",
        fontWeight: "400 !important",
        lineHeight: "18px !important",
        letterSpacing: "0px !important",
        textAlign: "left !important",
      },
    },

    
    MuiButton: {
      containedSecondary: {
        color: "#262626",
        height: "50px",
        fontSize: "16px",
        backgroundColor: "#F2F2F2",
        border: "none",
        fontWeight: 400,
        lineHeight: "normal",
        fontFamily: "Roboto Condensed",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: "#F2F2F2",
        },
        "@media (max-width :480px)":{
          fontSize:"12px"
        }
      },

      containedPrimary: {
        color: "#fff",
        height: "50px",
        fontSize: "16px",
        backgroundColor: "#0856CC",
        border: "none",
        fontWeight: 500,
        lineHeight: "normal",
        fontFamily: "Roboto Condensed",
        border:"1px solid transparent",
        borderRadius: "5px",
        "&:hover": {
          color: "#fff",
          border: "1px solid #0856CC",
          backgroundColor: "#0856CC",
        },
        "@media (max-width :480px)":{
          fontSize:"12px"
        }
      },

      contained: {
        color: "#fff",
        height: "40px",
        padding: "10px 39px",
        fontSize: "14px",
        background: "linear-gradient(275deg, #4D164F 4.07%, #681E65 98.21%)",
        border: "1px solid #4D164F",
        fontWeight: "500",
        lineHeight: "21px",
        fontFamily: "Outfit",
        borderRadius: "50px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
        backgroundColor:
          "linear-gradient(275deg, #4D164F 4.07%, #681E65 98.21%)",
        "&:hover": {
          color: "#fff",
          border: "1px solid #E1E1E1",
          backgroundColor: "#F2F2F2",
        },
      },

      outlinedPrimary: {
        color: "#080515",
        border: "1px solid #4D164F",
        padding: "5px 25px",
        fontWeight: "500",
        borderRadius: "50px",
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "#4D164F",
          border: "1px solid #4D164F",
          color: "#fff",
        },
      },

      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "Roboto Condensed",
  },

  palette: {
    type: "light",

    base: {
      light:'#FFFFFF',
      dark: '#000000',
    },

    action: {
      primary: "#20509e",
    },

    background: {
      default: "#fff",
      dark: "#f3f7f9",
      secondary: '#F4F4F4',
      paper: colors.common.white,
    },

    primary: {
      main: "#0856CC",
      dark: "#080515",
      light: "rgba(0, 0, 0, 0.60)",
      primary25: '#F5F8FF',
      primary50: '#EFF4FF',
      primary100: '#D1E0FF',
      primary200: '#B2CCFF',
      primary300: '#84ADFF',
      primary400: '#528BFF',
      primary500: '#2970FF',
      primary600: '#155EEF',
      primary700: '#004EEB',
      primary800: '#0040C1',
      primary900: '#00359E',
      primary950: '#002266',
      

    },
    secondary: {
      main: "#262626",
    },

    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
      warning25: "#FFFCF5",
      warning50: "#FFFAEB",
      warning100: "#FEF0C7",
      warning200: "#FEDF89",
      warning300: "#FEC84B",
      warning400: "#FDB022",
      warning500: "#F79009",
      warning600: "#DC6803",
      warning700: "#B54708",
      warning800: "#93370D",
      warning900: "#7A2E0E",
      warning950: "#4E1D09", 
    },

    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
      success25: "#F6FEF9",
      success50: "#ECFDF3",
      success100: "#DCFAE6",
      success200: "#ABEFC6",
      success300: "#75E0A7",
      success400: "#47CD89",
      success500: "#17B26A",
      success600: "#079455",
      success700: "#067647",
      success800: "#085D3A",
      success900: "#074D31",
      success950: "#053321",
      
    },

    error: {
      main: "#DC0404",
      dark: "#DC0404",
      light: "#DC0404",
      error25: "#FFFBFA",
      error50: "#FEF3F2",
      error100: "#FEE4E2",
      error200: "#FECDCA",
      error300: "#FDA29B",
      error400: "#F97066",
      error500: "#F04438",
      error600: "#D92D20",
      error700: "#B42318",
      error800: "#912018",
      error900: "#7A271A",
      error950: "#55160C",
      
    },

    text: {
      primary: "#636880",
      secondary: "rgba(8, 5, 21, 0.60)",
    },
    common: {
      black: "#222222",
    },

    gray: {
      gray25: "#FCFCFD",
      gray50: "#F9FAFB",
      gray100: "#F2F4F7",
      gray200: "#EAECF0",
      gray300: "#D0D5DD",
      gray400: "#98A2B3",
      gray500: "#667085",
      gray600: "#475467",
      gray700: "#344054",
      gray800: "#182230",
      gray900: "#101828",
      gray950: "#0C111D",
    }
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
