import React from "react";

const Logo = (props) => {
  return (
    <img
      src={
        props?.skillMarketPlaceRoute
          ? "/images/marketplacelogo.svg"
          : "/images/logo.svg"
      }
      alt="Logo"
      {...props}
    />
  );
};

export default Logo;
