export default {
  h1: {
    '&::first-letter': {
      textTransform: 'capitalize !important',
    },
    fontWeight: 500,
    fontSize: 45,
    lineHeight: "52px",
    fontFamily: "Roboto Condensed",
    "@media(max-width:767px)": {
      fontSize: "30px !important",
      lineHeight: "40px",
    },
  },
  h2: {
    '&::first-letter': {
      textTransform: 'capitalize !important',
    },
    fontWeight: 400,
    fontSize: 40,
    lineHeight: "45px",
    fontFamily: "Roboto Condensed",
    wordBreak: "break-word",
    "@media(max-width:767px)": {
      fontSize: "25px !important",
      lineHeight: "30px !important",
    },
  },
  h3: {
    '&::first-letter': {
      textTransform: 'capitalize !important',
    },
    fontWeight: 600,
    fontSize: 28,
    lineHeight: "40px",
    fontFamily: "Roboto Condensed",
    "@media(max-width:767px)": {
      fontSize: "18px !important",
    },
  },
  h4: {
    // '&::first-letter': {
    //   textTransform: 'capitalize !important',
    // },
    fontWeight: 700,  
    fontSize: 24,
    lineHeight: "normal",
    fontFamily: "Roboto Condensed",
    "@media(max-width:767px)": {
      fontSize: "18px !important",
    },
  },
  h5: {
    '&::first-letter': {
      textTransform: 'capitalize !important',
    },
    wordWrap:'break-word !important',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "30px",
    fontFamily: "Roboto Condensed",
    "@media(max-width:767px)": {
      fontSize: "16px !important",
    },
  },
  h6: {
    '&::first-letter': {
      textTransform: 'capitalize !important',
    },
    wordWrap:'break-word !important',
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "24px",
    fontFamily: "Roboto Condensed",
    "@media(max-width:767px)": {
      lineHeight: "22px",
      fontSize: "14px !important",
    },
  },
  overline: {
    fontWeight: 500,
    fontFamily: "'Poppins', sans-serif",
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "Roboto Condensed",
  },
  body2: {
    '&::first-letter': {
      textTransform: 'capitalize !important',
    },
    wordWrap:'break-word !important',
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "20px",
    fontFamily: "Roboto Condensed",
    "@media(max-width:767px)": {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  body1: {
    '&::first-letter': {
      textTransform: 'capitalize !important',
    },
    wordWrap:'break-word !important',
    fontWeight: 400,
    lineHeight: "20px",
    fontSize: 12,
    fontFamily: "Roboto Condensed",
  },
};
