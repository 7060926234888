import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

import { Box } from "@material-ui/core";
import Footer from "../HomeLayout/Footer";
import FooterCopyRight from "src/component/FooterCopyRight";
// import SettingsContext from "src/context/";

const useStyles = makeStyles((theme) => ({
 
  root: {
    backgroundColor: "#eef4f8",
    // overflow: "hidden",
    position: "relative",
    // height: "100vh",
    display: "flex",
  },

  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // position: "relative",
    // backgroundColor: "#eef4f8",
    [theme.breakpoints.down("xs")]: {
        padding: "65px 0px 0px 0px !important",
    
    },
    padding: '100px 20px 20px 20px',
    minHeight: "calc(100vh - 75px)",
    [theme.breakpoints.up("lg")]: {
    },
    // "@media (max-width:767px)": {
    //   paddingTop: "65px !important",
    // },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    flexDirection:'column'
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    // background:'#FFF',
    // padding: "18px 26px",
    
    [theme.breakpoints.down("md")]: {
      padding: "25px 10px 10px ",
      borderRadius:"0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px ",
    },
  },
  footer:{
    padding:'20px  10px 0 10px ',
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  // const themeSeeting = useContext(SettingsContext);

  return (
    <>
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
            <div className={classes.content} id="main-scroll">
              {children}
            </div>
            <div className={classes.footer}>
              <FooterCopyRight/>
            </div>
        </div>
      </div>
    </div>
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
